import 'lazysizes'
//import mixpanel from 'mixpanel-browser';
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './src/styles/theme.css'
import * as gtag from 'gatsby-plugin-google-gtag'
import ReactDOM from "react-dom/client";

// exports.shouldUpdateScroll = ({
//     routerProps: { location },
//     getSavedScrollPosition
// }) => {
//     const currentPosition = getSavedScrollPosition(location)
//     // const queriedPosition = getSavedScrollPosition({ pathname: `/random` })

//     window.scrollTo(...(currentPosition || [0, 0]))

//     return false
// }

// import React from 'react'
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// export const wrapRootElement = ({ element }) => {
//     return (
//         <GoogleReCaptchaProvider reCaptchaKey="6LfOLvMUAAAAAFHq_s5ZNJbZ7M1oXWoL9tvgIUt8"
//             scriptProps={{
//                 async: true, // optional, default to false,
//                 appendTo: 'head', // optional, default to "head", can be "head" or "body",
//             }} >
//             {element}
//         </GoogleReCaptchaProvider>
//     )
// }

// mixpanel.init('480c8e670aaecc4cc5612ffe4be59a8e', {debug: true});
// mixpanel.track('Sign Up', {
//   'source': "Pat's affiliate site",
//   'Opted out of email': true,
// });

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath })
    }
  }, 100)
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}